export enum ZeVersion {
  Alpha = 'alpha',
  Beta ='beta',
  Default = 'default'
}

export enum ZeViews {
  ProductSelection = 'productSelection',
  FinishedOnlinePayment = 'finishedOnlinePayment',
  BusinessActivitySelection = 'businessActivitySelection',
  AsegasaFullForm = 'asegasaFullForm',
  PostalAddress = 'postalAddress',
  OnlinePaymentUserData = 'onlinePaymentUserData',
  SelectedProducts = 'selectedProducts',
  CoversSummary = 'coversSummary',
  TelephonePaymentConfirmation = 'telephonePaymentConfirmation',
  PreparingPrice = 'preparingPrice',
  HolderAndRiskResume = 'holderAndRiskResume',
  EmailAndPhone = 'emailAndPhone',
}

export enum ZeViewProperties {
  ViewVersion = 'viewVersion',
  Sections = 'sections',
  Constants = 'constants'
}

//Input Enums
export enum ZeInputTypes {
  Text = 'text',
  Number = 'number',
  Currency = 'currency'
}

export enum ZeInputSizes {
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
  Full = 'full',
  Custom = 'custom'
}

export enum ZeInputFields {
  Name= 'name',
  LegalName = 'legalName',
  Surname = 'surname',
  IdDocument = 'idDocument',
  CompanyNif = 'companyNif',
  Nif = 'nif',
  Cif = 'cif',
  CompanyName = 'companyName',
  Email = 'email',
  Phone = 'phone',
  City = 'city',
  Province = 'province',
  ZipCode = 'zipCode',
  StreetName = 'streetName',
  ContactPhone = 'contactPhone'
}

export enum ZeButtonSizes {
  Small = 'small',
  Large = 'large'
}

//Event enums
export enum ZeInputEvents {
  Update = 'update',
  InputIsActive = 'inputIsActive',
  UpdateFormReference = 'update:formReference',
  IsValidStreetType = 'isValidStreetType',
  IsValidatedAddress = 'isValidatedAddress',
  IsValidForm = 'isValidForm',
  ShouldValidate = 'shouldValidate',
  DisableGeocoding = 'disableGeocoding',
  Validation = 'validation',
  Search = 'search',
  FullSearch = 'fullSearch',
  Clear = 'clear',
  DropdownVisibilityChange = 'dropdownVisibilityChange',
}

export enum ZeEvents {
  Enable = 'enable',
  OnClose = 'onClose',
  OnIbanError = 'onIbanError',
  Refresh = 'refreshHeader',
  Previous = 'previousStep',
  AddressRetry = 'addressRetry',
  AddressContinue = 'addressContinue',
  UpdateVisible = 'update:visible',
  UpdateInfoTooltip = 'update:infoTooltip',
  Input = 'input',
  InputIconClick = 'inputIconClick',
  OnNoMeasures = 'onNoMeasures',
  OnYesMeasures = 'onYesMeasures',
  AddonsChange = 'addonsChange',
  ShowDetails = 'showDetails',
  ShowProductIpid = 'showProductIpid',
  ShowNotaMeadiador = 'showNotaMediador',
  PhonePayment = 'phonePayment',
  OnlinePayment = 'onlinePayment',
  DisplayCoverageType = 'displayCoverageType',
  ResultChange = 'resultChange',
  OptionSelected = 'optionSelected',
  SelectionChange = 'selectionChange',
  InactiveCommerce = 'inactiveCommerce',
  ChangeStep = 'changeStep',
  CoverageDateChanged = 'coverageDateChanged',
  PaymentOptionSelection = 'paymentOptionSelection',
  RequotePrices = 'requotePrices',
  OnContentChangePrice = 'onContentChangePrice',
  OnContinentChangePrice = 'onContinentChangePrice',
  RestartModel = 'restartModel',
  EnableUserAbandon = 'enableUserAbandon',
  EnableExitDialog = 'enableExitDialog',
  DisableUserAbandon = 'disableUserAbandon',
  OpenChat = 'openChat',
  InfoExceptionalError = 'infoExceptionalError',
  Accept = 'accept',
  Decline = 'decline',
  RestartCart = 'restartCart',
  Selected = 'selected',
}

export enum GlobalEvents {
  Buy = 'buy'
}

export enum GlobalEventsMessages {
  Success = 'success',
  Failure = 'failure'
}

export enum ZeDateTypes {
  Days = 'days',
  Day = 'day'
}

export enum ZeDateFormats {
  DayMonthYear = 'DD/MM/YYYY',
  YearMonthDay = 'YYYY-MM-DD'
}

export enum ZeAnalyticsEventCategories {
  Call = 'call',
}

export enum ZeAnalyticsEventActions {
  Calling = 'calling',
  Back = 'back',
  Cancel = 'cancel',
  Quote = 'quote',
  Payment = 'payment'
}

export enum CRMEvents {
  ViewVisit = 'viewVisit',
  Abandon = 'abandon',
  FirstQuoteFailure = 'firstQuoteFailure'
}

// Card choice
export enum CardChoiceSelection {
  Single = 'single',
  SingleNone = 'single-none',
  Multi = 'multi'
}

export enum CardChoiceEvents {
  Selected = 'selected',
  ToggleInfo = 'toggleInfo'
}

export enum UpdateCardsActions {
  Open = 'open',
  Close = 'close'
}

export enum ComparisonAssertionEnum {
  BiggerThan = 'biggerThan',
  BiggerOrEqualThan = 'biggerOrEqualThan',
  SmallerThan = 'smallerThan',
  SmallerOrEqualThan = 'smallerOrEqualThan',
  EqualTo = 'equalTo'
}

export enum ZEIdentifierActions {
  Default = 'defaultNoEvent',
  Abandon = 'abandon',
  FirstQuote = 'firstQuote',
  FirstQuoteError = 'firstQuoteError',
  RequoteDate = 'requoteDate',
  RequoteCapitals = 'requoteCapitals',
  QuoteUpdateInfo = 'quoteUpdateInformation',
  OpenCallMeBackDialog = 'openCallMeBackDialog',
  ClickNextButton = 'clickNextButton',
  ClickBackButton = 'clickBackButton',
  ValidateForm = 'validateForm',
  RequestCallMeBack = 'requestCallMeBack',
  UserCallsNow = 'userCallsNow',
  ActivitySearch = 'activitySearch',
  ActivitySearchOpenList = 'activitySearchOpenList',
  ActivitySelected = 'activitySelected',
  AddPersonalInformation = 'addPersonalInformation',
  TelephonePayment = 'telephonePayment',
  OnlinePayment = 'onlinePayment',
  OnlinePaymentFailure = 'onlinePaymentFailure',
  SelectedProductViewVisit = 'selectedProductViewVisit'
}

export enum MpmCallbackType {
  CallMeBack = 'ZcallmeBack',
  CallMeNow = 'ZCallmeNow'
}

//Products
export enum ProductCode {
  Commerce = '00526',
  PYMES = '00516',
  RC = '00846',
  RcPro = '00847',
  RcDo = '00886',
  Accidents = '00215',
  Cyber = '00800'
}

export enum ProductId {
  Commerce = 'commerce',
  Accidents = 'accidents',
  RC = 'onlyRC',
  RcDo = 'rcAdmins',
  Cyber = 'cyberRisk',
  RcPro = 'rcpro'
}

export enum ProductOptionConditionType {
  Default = 'default', //Default behaviour (Regardless of condition)
  CompetenceManagement = 'competenceManagement',
  GuideMe = 'guideMe',
  LetMe = 'letMe'
}

export enum ZeProductBehaviour {
  TriggerCallMeBack = 'triggerCallMeBack'
}

export enum ZeProductCardVisibility{
  Both= 'both',
  PriceWrapper = 'priceWrapper',
  AddonsWrapper = 'addonsWrapper'
}

export enum QuoteResponseType {
  Commerce = 'quoteResponse',
  Accidents = 'quoteAccidentsResponse',
  RcPro = 'quoteRcProfessionalResponse'
}

export enum RequoteType {
  CapitalsChange = 'capitalModifications',
  DateChange = 'dateChange',
  SendBudget = 'sendBudget'
}

// Business Details
export enum PersonTypeEnum {
  Company = 'company',
  FreelanceWithEmployees = 'freelanceWithEmployees',
  FreelanceWithoutEmployees = 'freelanceWithoutEmployees',
}

//Building Details
export enum BuildingTypeEnum {
  OfficeInApartmentOrOfficeBuilding = 'officeInApartmentOrOfficeBuilding',
  OfficeInIndependentBuilding = 'officeInIndependentBuilding',
  Mall = 'mall',
  Kiosk = 'kiosk',
  PublicMarket = 'publicMarket',
  IndustrialUnit = 'industrialUnit'
}

export enum BuildingTypeCodes {
  OfficeInApartmentOrOfficeBuilding = 'V1',
  OfficeInIndependentBuilding = 'C1',
  Mall = 'C2',
  Kiosk = 'KI',
  PublicMarket = 'MP',
  IndustrialUnit = 'NI'
}

// Quote
export enum QuoteType {
  T1 = 'T1',
  T2 = 'T2',
  PI = 'PI'
}

export enum ClientRole {
  Tomador = 'Tomador',
  Asegurado = 'Asegurado',
  Pagador = 'Pagador'
}

//Payments
export enum PaymentTypes {
  Yearly = 'A',
  Biannual = 'S',
  Quarterly = 'T',
  Monthly = 'M'
}

export enum PaymentMedium {
  Bank = 'BC',
  Intermediary = 'IN',
  CreditCard = 'CC'
}

export enum Addons {
  Teletrabajo = 'teletrabajo',
  Duo = 'duo',
  Delivery = 'delivery',
  RemoteTasks = 'remoteTasks',
  WorkFromHome = 'workFromHome',
  Robbery = 'robbery',
  AseguradosAdicionales = 'aseguradosAdicionales',
  AmpliacionDefensaJuridica = 'ampliacionDefensaJuridica',
  RCPatronal150000 = 'RCPatronal150000',
  RCPatronal300000 = 'RCPatronal300000',
  ComputersBreakdown = 'computersBreakdown',
  MachineryBreakdown = 'machineryBreakdown',
  RefrigeratedProductDamage = 'refrigeratedProductDamage',
}

//Flows
export enum FlowId {
  GetPrice = 'getPrice'
}

export enum FlowAssistanceType {
  ShowAssistanceType = 'showAssistanceType',
  NoAssistanceType = 'noAssistanceType'
}

export enum UnderlineAssistanceType {
  NeedAdvice = 'needAdvice',
  NoAdvice = 'noAdvice',
}

//Links and Urls
export enum SiteLinksTypes {
  MainSite = 'mainSite',
  PrivacyPolicy = 'privacyPolicy',
  CookiesPolicy = 'cookiesPolicy',
  HiringPolicy = 'hiringPolicy',
  InformativeNote = 'informativeNote'
}

//Configs Enums
export enum FeatureName {
  bannerInfo = 'bannerInfo',
  chat = 'chat',
  crm = 'crm',
  analytics = 'analytics',
  webIdentifier = 'webIdentifier',
  medallia = 'medallia',
  clientVision = 'clientVision',
  emailAndPhoneViewFiscalIdField = 'emailAndPhoneViewFiscalIdField',
  activitySearch = 'activitySearch',
  //Promotional
  meetinglawyersPromotion = 'meetinglawyersPromotion',
  //Experiments
  pricingFixedFrequency = 'pricingFixedFrequency',
  //General Tests
  testFeature = 'testFeature',
}

export enum FeatureVariants {
  Salesforce = 'salesforce',
  MPMDirect = 'mpmDirect',
  //Experiments
  PricingFrequencyYearly = 'pricingFrequencyYearly',
  PricingFrequencyDaily = 'pricingFrequencyDaily',
  //Tests
  Test1 = 'test1',
  Test2 = 'test2'
}

//User data information
export enum IdDocumentTypeCodes {
  CIF = 'C',
  DNI = 'N',
  NIE = 'R'
}

//Only CMB Flow Types
export enum OnlyCmbFlowTypes {
  GE = 'GE' //Grandes Empresas
}


//Images
export enum ZeImgSrcType {
  Url = 'url', //Full url as a src
  Dir = 'dir' //Local directory as a src
}

export enum ZeImgSrcPath {
  Assets = 'assets',
  Public = 'public'
}

export enum ZeImgType {
  Icon = 'icons',
  Image = 'images'
}

export enum ZeImgExt {
  Svg = 'svg',
  Png = 'png',
  Jpg = 'jpg'
}

export enum ZeIcons {
  Offices = 'offices',
  Home = 'home',
  Owner = 'owner',
  LetMe = 'letme',
  LoveAndRomance = 'love-and-romance',
  Email = 'email',
  OfficesHome = 'offices-home',
  Mall = 'shopping-center',
  PublicMarket = 'public-market',
  IndependentMarket = 'market',
  Industry = 'industry',
  Kiosk = 'kiosk',
  Information = 'information',
  CheckCircle = 'check-circle',
  HandPersonOutline = 'hand-person-outline',
  Business = 'business',
  Signaling = 'signaling',
  Accident = 'accident',
  CyberRisk = 'cyberrisk',
  RcHammer = 'rc-hammer'
}

//Tree search
export enum TreeSearchSortingModes {
  Alphabetically = 'Alphabetically',
  ByPriority = 'byPriority',
  BySimilarity = 'bySimilarity'
}

//Address
export enum ZipCodeSelectorMode {
  Simple = 'simple',
  Dropdown = 'dropdown'
}

//Busines activities
export enum AsegasaBusinessActivities {
  ExpAgr = '8610212',
  AlqVeh = '8652499',
  MaqAgr = '8610224',
  TrabjAgr = '8610223',
}

export enum BusinessActivitiesWithoutEmployees {
  ProfeFormGuTur = '8653862',
  MonEquit = '8654620',
  OfiDespCall = '8652680',
  Socc = '8653421',
  VigSegur = '8655008'
}

export enum BusinessActivities {
  EstancoGeneral = '0301720',
  OpticaYProtesis = '752270'
}

export enum AsegasaActivities {
  ExpAgr = 'expAgr',
  AlqVeh = 'alqVeh',
  MaqAgr = 'maqAgr',
  TrabjAgr = 'trabjAgr',
  TractMot = 'tractMot',
}

export enum BusinessActivitiesConfig {
  ExpAgrFormConfig = 'ExpAgrFormConfig'
}

export enum AsegasaFormFields {
  TipoIdentificador = 'tipoIdentificador',
  IdentificadorFiscal = 'identificadorFiscal',
  CorreoElectronico = 'correoElectronico',
  CodigoPostal = 'codigoPostal',
  TipoVia = 'tipoVia',
  NombreVia = 'nombreVia',
  NumeroVia = 'numeroVia',
  InformacionAdicional = 'informacionAdicional',
  Facturacion = 'facturacion',
  NCabezasBravo = 'nCabezasBravo',
  NCabezasVacuno = 'nCabezasVacuno',
  CapacidadBalsas = 'capacidadBalsas',
  Hectareas = 'hectareas',
  NHectareasCotoCaza = 'nHectareasCotoCaza',
  NHectareasDehesa = 'nHectareasDehesa',
  NHectareasCosechable = 'nHectareasCosechable',
  Direccion = 'direccion'
}

export enum AsegasaComplexFormFields {
  Unidades = 'unidades',
  ListaMatriculaVehiculo = 'listaMatriculaVehiculo',
  ListaMatriculaCotoCaza = 'listaMatriculaCotoCaza',
  CodGanado = 'codGanado',
  Direccion = 'direccion'
}

export enum FormGeneratorFields {
  DynamicGenerator = 'dynamicGenerator',
  FieldAmount = 'fieldAmount'
}

// Killer questions
export enum PolicyholderStatementCodes {
  INRERC3AINHERC3A = 'INRERC3AINHERC3A',
  INRERC3A = 'INRERC3A',
  INHERC3A = 'INHERC3A',
  INDEPAVE = 'INDEPAVE',
  INIMFUUE = 'INIMFUUE',
  INACTRFU = 'INACTRFU',
  INTRFULO = 'INTRFULO'
}

export enum GarantyValues {
  N = 'N',
  S = 'S'
}

export enum BusinessDataType {
  CompanyData = 'companyData',
  UserData = 'onlinePaymentUserData'
}
