import {
  FeatureControl
} from '@/helpers/feature-control/features/featureControl';
import {
  BrandingFeaturesConfig
} from '@/utils/interfaces/configs/branding';

/**
 * Returns the current branding flow settings
 * @returns {FlowConfig}
 */
export default function getBrandingFeaturesSettings(): BrandingFeaturesConfig | undefined {
  const flowSettings = FeatureControl
    .getBrandingSettings
    .bind(FeatureControl)
    .call(FeatureControl, 'features') as BrandingFeaturesConfig | undefined;

  return flowSettings;
}
