import {
  EAFlowModel
} from '@zurich-es-npm/ea-front-web-core';
import {
  QuoteAndBuyResponse,
  QuoteAndBuyRequestModificacionesDatosCliente,
  QuoteAndBuyResponseDatosEmision,
  QuoteAndBuyRequest,
  EAQuoteAndBuyApi,
  QuoteAndBuyRequestElementosObjetoFactoresConIndicadores,
  QuoteAndBuyRequestElementosObjetoPropiedadLocalEnum,
  QuoteAndBuyRequestElementosObjetoTipoEdificioEnum,
  QuoteAndBuyRequestElementosObjetoUbicacionRiesgoEnum,
  QuoteAndBuyRequestElementosObjetoTipoGarantiasAseguradasEnum,
  QuoteAndBuyRequestElementosObjetoTipoContinenteEnum
} from '../../../services/V1/getPrice/quoteAndBuyOperation/post/api';
import {
  QuoteAndBuyAccidentsResponse,
  QuoteAndBuyAccidentsRequest,
  EAQuoteAndBuyAccidentsApi
} from '@/services/V1/getPrice/quoteAndBuyAccidentsOperation/post';
import {
  emptyOnlinePaymentUserData,
  OnlinePaymentUserDataModel
} from './views/payment/online-payment-user-data-step-view/online-payment-user-data-model';
import {
  FullAddressModel
} from './components/full-address-component/full-address-model';
import {
  ProductWithCoverage
} from './components/selected-product-coverages-info/selected-product-coverages-model';
import {
  CoversSummaryData
} from './components/covers-summary-list/covers-summary-data-structure';
import {
  PropertyCapitalValuesIndexModel
} from './views/price/selected-products-step/selected-products-step-models';
import {
  FlowInfoRequestProductosCodigoOperacionQuoteAndBuyEnum
} from '@/services/V1/getPrice/flowInfoOperation/post';
import {
  GetClientVisionResponseData,
} from '@/services/V1/getPrice/getClientVisionOperation/post/api';
import {
  CurrentSellerData
} from '@/utils/data/currentSellerData';
import {
  Steps
} from '@/helpers/feature-control/navigation/stepsConfig';
import {
  OnlyCmbFlowTypes,
  PaymentTypes,
  PolicyholderStatementCodes
} from '@/utils/enums';
import {
  AbTestingActiveExperimentsModel,
  ProductTableDocument,
  CorporateTableDocument,
  PersonType,
  ZeImgSources
} from '@/utils/interfaces';
import {
  SearchValueChild
} from '@/utils/interfaces/tree-search';
import ImgLoaderModel from '@/business-components/img-loader/img-loader-model';
import TreeSearchModel from '@/business-components/tree-search/tree-search-model';
import AsegasaFullForm from '@/flows/flows-views/get-price/views/forms/full-asegasa-form/full-asegasa-form-model';
import {
  EAQuoteAndBuyRcProfessionalApi,
  QuoteAndBuyRcProfessionalRequest,
  QuoteAndBuyRcProfessionalResponse
} from '@/services/V1/getPrice/quoteAndBuyRcProfessionalOperation/post';
import {
  EAQuoteAndBuyRcApi
} from '@/services/V1/getPrice/quoteAndBuyRcOperation/post';
import {
  AlqVehModel, ExpAgrModel, MaqAgrModel, TrabjAgrModel
} from './views/forms/full-asegasa-form/models';
import ZeTpvModel from '@/business-components/ze-tpv/ze-tpv-model';
export interface CardChoice {
  id: string;
  text: string;
  icon: string;
  subtitle?: string;
  infoText?: string;
  isInfoActive?: boolean;
  isInfoClosing?: boolean;
  defaultSelected?: boolean;
  optionCode?: string | boolean;
  optionCodeName?: string;
  productData?: ProductTableDocument;
  hasTooltip?: boolean;
  isNoneOption?: boolean;
  isSpecialIndicator?: boolean;
  operationCode?: FlowInfoRequestProductosCodigoOperacionQuoteAndBuyEnum;
  iconCustomHeight?: boolean;
  helperText?: string;
  tag?: {
    text?: string
  };
}

export interface CardChoiceCustomStyles {
  cardChoiceGroupWrapper?: object;
  cardChoiceWrapper?: object;
  cardChoiceBody: object;
  cardTextWrapper: object;
}

export interface AvailableProduct extends CardChoice {
  isProductAdded: boolean;
  price?: string;
  priceFrom?: number;
  hasCompetenceManagement?: boolean;
}

export interface FactorsModel {
  CDFACTO1: string;
  CDFACTO2: string;
  CDFACTO3: string;
  CDFACTO4: string;
  CDFACTO5: string;
  CDFACTO6: string;
  CDFACTO7: string;
  CDFACTO8: string;
  [key: string]: string;
}

export interface OrientativePricesData {
  CDPRODTE: string;
  CDCPIM01: string;
  CDCPIM02: string;
  CDLIMRCG: string;
  CDPRIMIN: string;
  CDPRIMAX: string;
}

export interface SectionElementsDataStorage {
  [key: string]: {
    indexValue: number;
    topPosition: number;
  };
}

export interface Addon {
  isAvailable: boolean;
  isActive: boolean;
}
export interface AddonsModel {
  delivery: Addon;
  workFromHome: Addon;
  duo: Addon;
  ampliacionDefensaJuridica: Addon;
  aseguradosAdicionales: Addon;
  remoteTasks: Addon;
  [key: string]: Addon;
}

//TODO: this interface might be necessary in the future, leave it here just in case
export interface ProductsWithAddonsModel {
  commerce: AddonsModel;
}

export interface AddonCardOption {
  id: string;
  name: string;
  infoText: string;
  byBranding?: string[];
  fixedNoPrice?: boolean;
}

export interface PolicyholderStatements {

  /**
   * In case there are policies to be answered independently, 
   * this array will contain the policy codes that need to be answered
   * NOTE: this might get deleted
   */
  policiesToDeclareByPage?: PolicyholderStatementCodes[][];
  pageToDeclareIndex?: number;
  INTRFULO?: string;
}

export enum GetPriceModelProperties {
  Name = 'name',
  Email = 'email',
  ContactPhone = 'contactPhone',
  LegalTerms1 = 'legalTerms1'
}

export enum CRMSteps {
  CRM01 = 'CRM-01',
  CRM02 = 'CRM-02',
  CRM03 = 'CRM-03',
  CRM04 = 'CRM-04',
  CRM05 = 'CRM-05',
  CRM06 = 'CRM-06',
  CRM07 = 'CRM-07',
  CRM08 = 'CRM-08'
}

export enum OptionalCRMProperties {
  Holder = 'holder',
  TimeRange = 'timeRange',
  ActiveUserState = 'activeUserState',
  FirstQuote = 'firstQuote',
  UserWillCall = 'userWillCall',
  FirstPagePassed = 'firstPagePassed',
  Requote = 'requoteT2',
  Abandon = 'abandon',
  WrongIbanNumber = 'wrongIbanNumber'
}

export enum CRMUserState {
  Phone = 'Telefonico',
  Callmeback = 'Callmeback',
  New = 'Activo',
  Abandoned = 'Abandono'
}

export enum UrlSearchParams {
  WebIdentifier = 'webCustomerID'
}

export interface TarificationBodyRelationsModel {
  name: string;
  hasPrice?: boolean;
  body: QuoteAndBuyRequest | QuoteAndBuyAccidentsRequest | QuoteAndBuyRcProfessionalRequest;
  api: EAQuoteAndBuyApi | EAQuoteAndBuyAccidentsApi | EAQuoteAndBuyRcProfessionalApi | EAQuoteAndBuyRcApi;
}

export interface DiscountOption {
  discountType?: string;
  discountAmount?: string;
  value?: PaymentTypes;
  dirty?: boolean;
}

export interface QuoteResponseEconomicData {
  recurrentPaymentsTotal?: number;
  paymentType?: string;
}

export interface CRMBestTimeToCallModel {
  dayToCall?: TelephonePaymentZurichCallsDay;
  timeToCall?: {
    startHour: number;
    endHour: number;
  };
}

export enum GuideMeLetMeMode {
  Default = 'default',
  GuideMe = 'guide-me',
  LetMe = 'let-me'
}

export enum PartInsured {
  Property = 'property',
  Furniture = 'furniture',
  PorpertyFurniture = 'propertyFurniture'
}

export enum ShoppingCenterVsHome {
  ShoppingCenter = 'shoppingCenter',
  Home = 'home'
}

export enum PaymentMethod {
  Telephone = 'telephone',
  Online = 'online'
}

export enum TelephonePaymentOption {
  CallToMe = 'receiveCall',
  IWillCall = 'iWillCall'
}

export enum TelephonePaymentZurichCallsDay {
  AsSoonAsPossible = 'asSoonAsPossible',
  OneWorkingDay = 'oneWorkingDay',
  TwoWorkingDays = 'twoWorkingDays'
}

export enum TelephonePaymentZurichCallsTime {
  From9To11 = 'from9To11',
  From11To13 = 'from11To13',
  From13To15 = 'from13To15',
  From15To17 = 'from15To17',
  From15To18 = 'from15To18',
  From17To20 = 'from17To20',
  From10To12 = 'from10To12',
  From12To15 = 'from12To15'
}

export enum TelephonePaymentZurichCallsTimeCase {
  August = 'august',
  StandardWeekday = 'standardWeekday',
  StandardFriday = 'standardFriday',
  StandardSaturday = 'standardSaturday'
}

export enum WindowsType {
  Normal = 'TCTIPLUN1',
  DoubleLayer = 'TCTIPLUN2',
  TripleLayer = 'TCTIPLUN3',
  NotSure = 'not-sure'
}

export enum AvailableProducts {
  Commerce = '00526',
  PYMES = '00516',
  RC = '00845',
  RcPro = '00847',
  RCDO = '00886',
  Accidents = '00215',
  Cyber = '00800'
}

export enum ProductId {
  Commerce = 'commerce',
  Accidents = 'accidents',
  RC = 'onlyRC',
  RcDo = 'rcAdmins',
  Cyber = 'cyberRisk',
  RcPro = 'rcpro'
}

export enum InputTypes {
  text = 'text',
  currency = 'currency',
  number = 'number',
  textarea = 'textarea'
}

export enum InputSizes {
  Small = 'small',
  Medium = 'medium',
  Big = 'big',
  Full = 'full'
}

export enum ReformTypes {
  insure = 'wantsReform',
  noInsure = 'doesntWantReform',
  noRenovation = 'dontHaveReform'
}

export enum ModelQuotes {
  quoteResponse = 'commerce',
  quoteAccidentsResponse = 'accidents',
  quoteRcProfessionalResponse = 'rcpro'
}

export enum ErrorTypes {
  none = 'none',
  productSelection = 'productSelection',
  emissionError = 'emissionError',
  genericError = 'genericError',
  genericError3 = 'genericError3',
  errorQuoteService = 'errorQuoteService',
  eligibilityError = 'elegibilityError',
  ibanError = 'ibanError',
  ccFormatError = 'ccFormatError',
  fullAddressValidationError = 'fullAddressValidationError',
  infoMaintenanceErrorNoCC = 'infoMaintenanceErrorNoCC',
  infoMaintenanceErrorYesCC = 'infoMaintenanceErrorYesCC',
  infoOutOfWorkingHoursNotice = 'infoOutOfWorkingHoursNotice'
}

export interface SelectedCoverage {
  id: ProductId;
  name?: string;
  icon?: string;
  infoText?: string;
  activityName?: string;
  activityCode?: string;
  price?: number;
  priceFrom?: number;
  optionCode?: string;
  options?: SelectedCoverageOptions;
  policyCode?: string;
  hasCompetenceManagement?: boolean;
  policyInformation?: { [key: string]: any };
}

export interface SelectedCoverageOptions {
  moreDetails?: boolean;
  modifyPrice?: boolean;
  noPrice?: boolean;
  isReadonly?: boolean;
  hasIpid?: boolean;
  hasSpecificConditions?: boolean
}

export enum CallMeBackOrTelephonePayment {
  CallMeBack = 'callmeback',
  TelephonePayment = 'telephonepayment'
}


/**
 * Class dedicated to store data of views related to and for enhancing their functionality
 */
class ZEViewsModel {

  public shouldCRMRequestTrigger: boolean = false;

}

/**
 * Class dedicated to store data related to ab testing
 */
class AbTestingModel {

  public activeExperiments: AbTestingActiveExperimentsModel[] = [];
}

/**
 * Class dedicated to store data related to the CMB only flow mode
 */
class CmbOnlyFlowModel {

  public enabled: boolean = false;

  public type?: OnlyCmbFlowTypes;
}

/**
 * Get Price flow model
 *
 * @export
 * @class GetPriceModel
 * @extends {EAFlowModel}
 */
export class GetPriceModel extends EAFlowModel {

  //AbTesting
  public abTestingModel = new AbTestingModel();

  //CMB GE
  public cmbOnlyFlowModel = new CmbOnlyFlowModel();

  //Views Model
  public ZEViewsModel = new ZEViewsModel();

  //Full Asegasa Form
  public FullAsegasaForm = new AsegasaFullForm();

  //Flow Avatar
  public flowAvatar: ZeImgSources = {
    id: '',
    name: ''
  };

  //Flow Views Steps
  public FlowViewsSteps = Steps;

  //Name max characters
  public MAX_CHARS_NAME = 120;

  //Emails max characters
  public MAX_CHARS_EMAIL = 75;

  // Active step in stepper component
  public activeStep: number = 0;

  //Previous step of the flow
  public previousStep: number = 0;

  public isHeaderHelpButtonsRowInactive: boolean = false;

  //Sequential active step for the funnelStep of the GTM
  public sequentialActiveStep: { value: number; formattedValue: string } = {
    value: 0,
    formattedValue: ''
  };

  //Last step visited in the normal flow of the app
  public storedFlowStep: number = 0;

  // Flag to show or hide stepper component
  public showSteps: boolean = false;

  // Guide Me or Let Me
  public guideMeLetMeMode: GuideMeLetMeMode | undefined = GuideMeLetMeMode.Default;

  //User's first name
  public userFirstName: string = '';

  //Web Identifier for the user 
  public webIdentifier: string = '';

  //Client Vision Data
  public clientVisionData?: GetClientVisionResponseData;

  //Current Seller's data (Branding owner)
  public currentSellerData = new CurrentSellerData();

  /***** Form fields  ******/
  // Name
  public name: string = '';

  // Email
  public email: string = '';

  // Phone
  public phone: string = '';

  // Businnes name in email & phone step
  public businessName: string = '';

  //Company Legal Name
  public businessLegalName: string = '';

  // Authorization check in email & phone step
  public authPriceCheck: boolean = false;

  // Legal terms 1 check (step 2)
  public legalTerms1: boolean = false;

  // DNI / CIF
  public cif: string = '';

  public fiscalId = {
    value: '' as any,
    type: '' as any
  };

  // Annual billing
  public billing?: number = 0;

  // Employees
  public employees?: number = 0;

  public baseCalculationDescription?: string;

  public baseCalculationCode?: string;

  public address: FullAddressModel = new FullAddressModel();

  public addressCoordinates: { longitude?: number; latitude?: number } = {};

  // Building construction year
  public buildingYear?: number;

  // Building area (surface)
  public buildingArea?: number;

  // Owner or Rent
  public ownerRent: QuoteAndBuyRequestElementosObjetoPropiedadLocalEnum | undefined;

  // Property Reforms Options
  public propertyReform: ReformTypes | undefined;

  //Property reform value
  public propertyReformValue: number | undefined;

  // Building type emplacement
  public buildingType: QuoteAndBuyRequestElementosObjetoTipoEdificioEnum | undefined;

  // Floor Emplacement
  public floorEmplacement: QuoteAndBuyRequestElementosObjetoUbicacionRiesgoEnum | undefined;

  // Part insured
  public partInsured: {
    value: QuoteAndBuyRequestElementosObjetoTipoGarantiasAseguradasEnum;
    text: string;
  } | undefined;

  //Is the Madrid Community active
  public isMadridCommunityActive: boolean = false;

  //Minimum capital values (Madrid Community)
  public minimumCapitalOption?: PropertyCapitalValuesIndexModel | null;

  // Continent type: 'Immueble' or 'Obras'
  public continentType: QuoteAndBuyRequestElementosObjetoTipoContinenteEnum | undefined;

  // List of selected factors. NOTE: The value of each factor will be 'S' or 'N'
  public selectedFactorsData: FactorsModel = {
    CDFACTO1: '',
    CDFACTO2: '',
    CDFACTO3: '',
    CDFACTO4: '',
    CDFACTO5: '',
    CDFACTO6: '',
    CDFACTO7: '',
    CDFACTO8: '',
  };

  //Selected factors descriptions
  public selectedFactorsDescriptions: string[] = [];

  //Are factors visible in the data resume
  public isFactorsResumeDisabled: boolean = false;

  //Factors with special indicators
  public selectedFactorsWithIndicators: QuoteAndBuyRequestElementosObjetoFactoresConIndicadores[] = [];

  //Product addons model
  public addonsModel: AddonsModel = {
    delivery: {
      isActive: false,
      isAvailable: false
    },
    workFromHome: {
      isActive: false,
      isAvailable: false
    },
    duo: {
      isActive: false,
      isAvailable: false
    },
    ampliacionDefensaJuridica: {
      isActive: false,
      isAvailable: false
    },
    aseguradosAdicionales: {
      isActive: false,
      isAvailable: false
    },
    remoteTasks: {
      isActive: false,
      isAvailable: false
    },
    RCPatronal150000: {
      isActive: false,
      isAvailable: false
    },
    RCPatronal300000: {
      isActive: false,
      isAvailable: false
    },
    computersBreakdown: {
      isActive: false,
      isAvailable: false
    },
    machineryBreakdown: {
      isActive: false,
      isAvailable: false
    },
    refrigeratedProductDamage: {
      isActive: false,
      isAvailable: false
    },
  };

  //Is delivery addon active
  public isDeliveryAddonActive: boolean = false;

  //Is Work from home addon active
  public isWorkFromHomeAddonActive: boolean = false;

  public isAmpliacionDefensaJuridicaActive: boolean = false;

  public isAseguradosAdicionalesActive: boolean = false;

  public isRemoteTasksAddonActive: boolean = false;

  public isRCPatronal150000Active: boolean = false;

  public isRCPatronal300000Active: boolean = false;

  public isComputersBreakdownActive: boolean = false;

  public isMachineryBreakdownActive: boolean = false;

  public isRefrigeratedProductDamageActive: boolean = false;

  // Maximum Local Capacity
  public maxCapacity: number | undefined | null;

  // Windows type
  public windowsType: WindowsType | undefined;

  public windowsTypeDescription: string | undefined;

  //Safety Measures
  public safetyMeasures: string[] = [];

  public safetyMeasuresDescription: string[] = [];

  //Coverage starting date
  public coverageStartingDate: string = '';

  // Payment method
  public paymentMethod: PaymentMethod | undefined;

  // Phone payment option
  public telephonePaymentOption: TelephonePaymentOption | undefined;

  // Phone payment Zurich calls day
  public telephonePaymentZurichCallsDay: TelephonePaymentZurichCallsDay | undefined;

  // Phone payment Zurich calls time
  public telephonePaymentZurichCallsTime: TelephonePaymentZurichCallsTime | undefined;

  // Is call me back or telephone payment
  public isCallMeBackOrTelephonePayment: CallMeBackOrTelephonePayment | undefined;

  /***** Autocomplete search  ******/

  public businessActivityModel = new TreeSearchModel();

  public businessActivity: SearchValueChild = {
    name: '',
    value: '',
    activityValue: '',
    familyValue: '',
    familyName: '',
    familyCode: '',
    activityCode: '',
    technicalProductCode: '',
    canHaveEmployees: true
  };

  public preSelectedGroup: string = '';

  public preSelectedActivity: string = '';

  public preSelectedProducts: { techProd: string; commProd: string }[] = [];

  public skipLetMeSelection: boolean = false;

  public personType?: PersonType;

  //Identifies if the first quote have been completed
  public isFirstQuoteCompleted: boolean = false;

  public contactPhone: string = '';

  public IBANCodeValue: string = '';

  // Quote response
  public quoteResponse: QuoteAndBuyResponse | null = null;

  // Quote accidents response
  public quoteAccidentsResponse: QuoteAndBuyAccidentsResponse | null = null;

  // Quote RC professional response
  public quoteRcProfessionalResponse: QuoteAndBuyRcProfessionalResponse | null = null;

  //Quote operation code
  public quoteOperationCode: FlowInfoRequestProductosCodigoOperacionQuoteAndBuyEnum | undefined = undefined;

  //Orientative Prices Data
  public orientativePricesTableData?: OrientativePricesData[];

  //Selected discount option
  public selectedDiscountOption: DiscountOption = {};

  public isProductsTotalPriceInvalid: boolean = true;

  public selectedProductsCoveragesData: ProductWithCoverage[] = [];

  public onlinePaymentUserData: OnlinePaymentUserDataModel = {
    ...emptyOnlinePaymentUserData
  };

  //Determines if the take out button has been clicked
  public haveTakeOutBeenSelected: boolean = false;

  // Current step by CRM flow
  public crmStep: CRMSteps = CRMSteps.CRM01;

  // On before unload callback to capture user leaving the site
  public onBeforeUnloadFn: ((this: Window, ev: BeforeUnloadEvent) => boolean | null) | null = null;

  public onVisibilityChangeFn: ((this: Window, ev: Event) => void) | null = null;

  //Represents if any of the selected products have quoted price
  public allSelectedProductsHaveNoPrice: boolean = true;

  //Stores the client array with the selected information of the client for the emission
  public storedClientArray: QuoteAndBuyRequestModificacionesDatosCliente[] = [];

  //Stored the results of the emission
  public emissionResults: { [key: string]: QuoteAndBuyResponseDatosEmision | undefined } = {};

  public skipCrmAbandonTrigger: boolean = false;

  //Stored random number with fixed length, that is used for the id of the emission in the analytics
  public randomFixedLengthNumber: number = 0;

  //Dialog Error Types
  public DialogErrorTypes = ErrorTypes;

  // Flag to indicate when user has asked for help. Used in step TelephonePaymentZurichCallsStep
  public userAskedForHelp: boolean = false;

  public userAskedForAdvice?: boolean;

  // Admin first name to send CRM
  public adminFirstName?: string = '';

  // Admin last name to send CRM
  public adminLastName?: string = '';

  // Covers for every
  public coversData?: CoversSummaryData[];

  //Tables data
  public productsRelationData: string = '';

  public countriesTableData: CorporateTableDocument[] = [];

  public streetTypesList: CorporateTableDocument[] = [];

  public funnelStep: number = 1;

  //Time range for CRM calls
  public bestTimeToCallCRM: CRMBestTimeToCallModel = {};

  //Assets
  public picturesAssets = new ImgLoaderModel();

  //Info exceptional errors
  public infoExceptionInformation: string | undefined = '';

  //Agro Rc General Model
  public agroRcGeneral?: ExpAgrModel | TrabjAgrModel | AlqVehModel | MaqAgrModel;

  //Document Generation state
  public documentGenerationState: boolean = false;

  public policyholderStatements?: PolicyholderStatements;

  //Parametros extra
  public parametrosExtra: { codigoElemento: string, descripcionElemento: string }[] = [];

  public cardPayment: ZeTpvModel = new ZeTpvModel();
}
