import {
  GetPriceModelProperties,
  OptionalCRMProperties
} from '@/flows/flows-views/get-price/get-price-model';
import {
  ProductCode, ProductId, ProductOptionConditionType,
  ZeVersion,
  ZeProductCardVisibility,
  PaymentMedium
} from '@/utils/enums';
import {
  BrandingConfig
} from '@/utils/interfaces';

export const defaultOrangeBrandingConfig: BrandingConfig = {
  cookies: '39c162fb-f3d7-4789-b6bb-d94afd9e47bb',
  phoneNumbers: {
    contactCenter: '900 90 90 60'
  },
  links: {
    mainSite: 'https://www.orangeseguros.es/seguro-de-comercios',
    cookiesPolicy: 'https://orangesegurosbyzurich.zurich.es/legalesempresas#politica-de-cookies',
    privacyPolicy: 'https://orangesegurosbyzurich.zurich.es/legalesempresas#politica-de-privacidad',
    hiringPolicy: 'https://orangesegurosbyzurich.zurich.es/legalesempresas#proceso-de-contratacion',
    informativeNote: 'https://orangesegurosbyzurich.zurich.es/legalesempresas#nota-informativa-mediador'
  },
  coverageDefaultInitialDateOffset: 3,
  flow: {
    views: {
      productSelection:{
        viewVersion: ZeVersion.Default,
      },
      selectedProducts: {
        sections: {
          heroSection: {
            enabled: true,
            components: {
              buttonsWrapperComponent: {
                features: {
                  showButtons: {
                    enabled: false,
                  },
                  showProductDetailsButton: {
                    enabled: true,
                    enabledByProduct: [ProductId.Commerce],
                  },
                  showNotaMediadorButton: {
                    enabled: true,
                    enabledByProduct: [ProductId.Commerce, ProductId.RC, ProductId.RcPro],
                  },
                },
                constants : {
                  scrollPositionAdjusmentIndex: 110
                },
              }
            }
          },
          selectedProductsCoverages: {
            enabled: true,
            enabledByProduct: [ProductId.Commerce]
          },
          tempImgSection: {
            enabled: false
          },
          modifyCapitals: {
            enabled: true
          },
          additionalProducts: {
            enabled: false
          },
          faqs: {
            enabled: true
          },
          feedback: {
            enabled: false
          },
          meetinglawyersPromotion: {
            enabled: false
          },
        },
        constants: {
          canEnableNextButton: true
        }
      },
      emailAndPhone: {
        sections: {
          meetinglawyersPromotion: {
            enabled: false,
            enabledByProduct: [ProductId.Commerce]
          },
          header: {
            enabled: false,
            enabledByProduct: [ProductId.Commerce, ProductId.RC, ProductId.RcPro],
            components: {
              customHeader: {
                features: {
                  showReturnButton: {
                    enabled: false,
                    enabledByProduct: [ProductId.Commerce, ProductId.RC, ProductId.RcPro]
                  }
                }
              }
            }
          }
        },
        features: {
          isPhoneEditable: {
            enabled: false,
            enabledByProduct: [ProductId.Commerce, ProductId.RC, ProductId.RcPro]
          },
          showLoadingSpinner: {
            enabled: false
          },
          doCreateModificationsArray: {
            enabled: true,
            enabledByProduct: [ProductId.Commerce, ProductId.RC, ProductId.RcPro]
          },
          doCRMRequest: {
            enabled: false,
            enabledByProduct: [ProductId.Commerce, ProductId.RC, ProductId.RcPro]
          },
          waitForCompleteQueue: {
            enabled: false
          }
        }
      },
      coversSummary: {
        viewVersion: {
          version: ZeVersion.Beta,
          enabledByProduct: [ProductId.RC, ProductId.RcPro]
        },
        sections: {
          priceInfo: {
            enabled: true,
            components: {
              buttonsWrapperComponent: {
                features: {
                  showButtons: {
                    enabled: false,
                  },
                  showProductDetailsButton: {
                    enabled: true,
                    enabledByProduct: [ProductId.Commerce],
                  },
                  showNotaMediadorButton: {
                    enabled: true,
                    enabledByProduct: [ProductId.Commerce, ProductId.RC, ProductId.RcPro],
                  },
                },
                constants : {
                  scrollPositionAdjusmentIndex: 110
                },
              },
              selectedCoverageCardsSection: {
                constants: {
                  forceShowPriceWrapper: true,
                  forceShowProductsTitle: false
                }
              }
            }
          },
          coveragesTable: {
            enabled: true
          },
          productsCards: {
            enabled: true,
            enabledByProduct: [ProductId.Commerce]
          },
          documentationCard: {
            enabled: false
          },
        },
        features: {
          showNotaMediador: {
            enabled: true,
            enabledByProduct: [ProductId.Commerce, ProductId.RC, ProductId.RcPro],
          },
        },
        constants: {
          checkItemDocumentation: false
        }
      },
      onlinePaymentUserData: {
        viewVersion: ZeVersion.Default,
        sections: {
          personTypeSelection: {
            enabled: false
          },
          userDataForm: {
            enabled: true,
            components: {
              companyDataCapture: {
                constants: {
                  showManagerFirstName: false,
                  showManagerSurname: false
                }
              }
            }
          },
          budgetAlert: {
            enabled: false
          },
          documentationRowSection: {
            enabled: false
          },
        },
        constants:{
          showProductHaveNoPriceMessage: true,
          preventRequotePriceOnTransition: true
        }
      },
      holderAndRiskResume:{
        constants:{
          valuesToPersistInModel: [
            GetPriceModelProperties.Name,
            GetPriceModelProperties.Email,
            GetPriceModelProperties.ContactPhone,
            GetPriceModelProperties.LegalTerms1
          ]
        }
      },
      finishedOnlinePayment: {
        sections: {
          emissionMainMessage: {
            enabled: true,
            version: ZeVersion.Default
          },
          policyNumberCopy: {
            enabled: false,
            version: ZeVersion.Default
          },
          hiredProducts: {
            enabled: true,
            version: ZeVersion.Default,
            components: {
              buttonsWrapperComponent: {
                features: {
                  showButtons: {
                    enabled: false,
                  },
                  showProductDetailsButton: {
                    enabled: true,
                    enabledByProduct: [ProductId.Commerce],
                  },
                  showNotaMediadorButton: {
                    enabled: true,
                    enabledByProduct: [ProductId.Commerce, ProductId.RC, ProductId.RcPro],
                  },
                },
                constants : {
                  scrollPositionAdjusmentIndex: 110
                },
              }
            }
          },
          productPromotion: {
            enabled: false,
            version: ZeVersion.Default
          },
          buttonsSection: {
            enabled: true,
            version: ZeVersion.Default
          }
        },
        constants:{
          optionalCRMProperties:[
            OptionalCRMProperties.Holder
          ],
          generateUrlLocationParams: false
        }
      },
      businessActivitySelection:{
        viewVersion: {
          version: ZeVersion.Default,
        },
        sections: {
          cardsSelector: {
            enabled: false,
            version: ZeVersion.Default,
          }
        }
      },
      telephonePaymentConfirmation: {
        constants: {
          hideAvatar: false,
          showGoToHomesiteButton: true
        },
        features: {
          redirectWithParams: {
            enabled: false
          }
        }
      },
      preparingPrice: {
        constants: {
          hideAvatar: false
        },
        features: {
          checkCompetenceManagement: {
            enabled: true,
            enabledByProduct: [ProductId.Commerce, ProductId.RC, ProductId.RcPro],
          }
        }
      }
    },
    paymentType: {
      telephone: {
        enabled:true
      },
      online: {
        enabled: true,
        paymentChannel: {
          T1: PaymentMedium.Bank,
          T2: PaymentMedium.Bank,
          PI: PaymentMedium.Bank
        }
      }
    }
  },
  features: {
    chat: {
      enabled: false
    }
  },
  products: [
    {
      id: ProductId.Commerce,
      code: ProductCode.Commerce,
      options: {
        isRecommended: {
          condition: [
            {
              conditionType: ProductOptionConditionType.Default
            }
          ]
        },
        productCard: {
          partVisible: ZeProductCardVisibility.Both
        }
      }
    }
  ]
};
