import {
  appRoutes
} from './ea-router';

const vApplication = {
  /* eslint-disable no-process-env */
  /* eslint-disable no-undef */
  version: `v${process.env.VUE_APP_VERSION || process.env.VUE_APP_PACKAGE}`
};

const initialConfig = {
  routerConfig: {
    appRoutes: appRoutes
  }
};

export {
  initialConfig,
  vApplication
};
