import {
  GetPriceModelProperties,
  OptionalCRMProperties
} from '@/flows/flows-views/get-price/get-price-model';
import {
  ProductCode,
  ProductId,
  ProductOptionConditionType,
  ZeVersion,
  PaymentMedium
} from '@/utils/enums';
import {
  BrandingConfig
} from '@/utils/interfaces';

export const defaultSegurosolBrandingConfig: BrandingConfig = {
  cookies: '',
  phoneNumbers: {
    contactCenter: '912 70 32 64'
  },
  links: {
    mainSite: 'https://zurichempresa.uat-zurich.es/',
    cookiesPolicy: 'https://zurichempresas.es/politica-de-cookies',
    privacyPolicy: 'https://zurichempresas.es/politica-de-privacidad',
    hiringPolicy: 'https://zurichempresas.es/politica-de-contratacion',
    informativeNote: ''
  },
  coverageDefaultInitialDateOffset: 3,
  //Products that can exist in the app
  products: [
    {
      id: ProductId.Commerce,
      code: ProductCode.Commerce,
      options: {
        isRecommended: {
          condition: [
            {
              conditionType: ProductOptionConditionType.GuideMe,
            }
          ]
        },
        showPrice: {
          condition: [
            {
              conditionType: ProductOptionConditionType.CompetenceManagement,
              activityCode: '0202450' //Activity Code
            }
          ]
        }
      }
    }
  ],
  flow: {
    views: {
      productSelection:{
        viewVersion: ZeVersion.Default,
      },
      selectedProducts: {
        sections: {
          heroSection: {
            enabled: true,
            components: {
              buttonsWrapperComponent: {
                features: {
                  showButtons: {
                    enabled: false,
                  },
                  showProductDetailsButton: {
                    enabled: true,
                    enabledByProduct: [ProductId.Commerce],
                  },
                  showNotaMediadorButton: {
                    enabled: false
                  },
                },
                constants : {
                  scrollPositionAdjusmentIndex: 110
                },
              }
            }
          },
          selectedProductsCoverages: {
            enabled: true
          },
          tempImgSection: {
            enabled: false
          },
          modifyCapitals: {
            enabled: true
          },
          additionalProducts: {
            enabled: true
          },
          faqs: {
            enabled: true
          },
        },
      },
      emailAndPhone: {
        sections: {
          meetinglawyersPromotion: {
            enabled: false,
            enabledByProduct: [ProductId.Commerce, ProductId.RC, ProductId.RcPro]
          },
          header: {
            enabled: true,
            enabledByProduct: [ProductId.Commerce, ProductId.RC, ProductId.RcPro],
            components: {
              customHeader: {
                features: {
                  showReturnButton: {
                    enabled: true,
                    enabledByProduct: [ProductId.Commerce, ProductId.RC, ProductId.RcPro]
                  }
                }
              }
            }
          }
        },
        features: {
          isPhoneEditable: {
            enabled: true,
            enabledByProduct: [ProductId.Commerce, ProductId.RC, ProductId.RcPro]
          },
          showLoadingSpinner: {
            enabled: true
          },
          doCreateModificationsArray: {
            enabled: true,
            enabledByProduct: [ProductId.Commerce, ProductId.RC, ProductId.RcPro]
          },
          doCRMRequest: {
            enabled: true,
            enabledByProduct: [ProductId.Commerce, ProductId.RC, ProductId.RcPro]
          },
          waitForCompleteQueue: {
            enabled: false
          }
        }
      },
      coversSummary: {
        sections: {
          priceInfo: {
            enabled: true,
            components: {
              buttonsWrapperComponent: {
                features: {
                  showButtons: {
                    enabled: false,
                  },
                  showProductDetailsButton: {
                    enabled: true,
                    enabledByProduct: [ProductId.Commerce],
                  },
                  showNotaMediadorButton: {
                    enabled: false
                  },
                },
                constants : {
                  scrollPositionAdjusmentIndex: 110
                },
              },
              selectedCoverageCardsSection: {
                constants: {
                  forceShowPriceWrapper: false,
                  forceShowProductsTitle: false
                }
              }
            }
          },
          coveragesTable: {
            enabled: true
          },
          productsCards: {
            enabled: true,
            enabledByProduct: [ProductId.Commerce]
          },
          documentationCard: {
            enabled: false
          },
        },
        features: {
          showNotaMediador: {
            enabled: false,
          },
        },
        constants: {
          checkItemDocumentation: false
        }
      },
      onlinePaymentUserData: {
        viewVersion: ZeVersion.Default,
        sections: {
          userDataForm: {
            enabled: true,
            components: {
              companyDataCapture: {
                constants: {
                  showManagerFirstName: true,
                  showManagerSurname: true
                }
              }
            }
          },
          budgetAlert: {
            enabled: false
          },
          documentationRowSection: {
            enabled: false
          }
        },
        constants: {
          preventRequotePriceOnTransition: true
        }
      },
      holderAndRiskResume:{
        constants:{
          valuesToPersistInModel: [
            GetPriceModelProperties.Name,
            GetPriceModelProperties.Email
          ]
        }
      },
      finishedOnlinePayment: {
        sections: {
          emissionMainMessage: {
            enabled: true,
            version: ZeVersion.Default
          },
          policyNumberCopy: {
            enabled: false,
            version: ZeVersion.Default
          },
          hiredProducts: {
            enabled: true,
            version: ZeVersion.Default,
            components: {
              buttonsWrapperComponent: {
                features: {
                  showButtons: {
                    enabled: false,
                  },
                  showProductDetailsButton: {
                    enabled: true,
                    enabledByProduct: [ProductId.Commerce],
                  },
                  showNotaMediadorButton: {
                    enabled: false
                  },
                },
                constants : {
                  scrollPositionAdjusmentIndex: 110
                },
              }
            }
          },
          productPromotion: {
            enabled: false,
            version: ZeVersion.Default
          },
          buttonsSection: {
            enabled: true,
            version: ZeVersion.Default
          }
        },
        constants:{
          optionalCRMProperties:[
            OptionalCRMProperties.Holder
          ],
          generateUrlLocationParams: false
        }
      },
      businessActivitySelection:{
        viewVersion: {
          version: ZeVersion.Default,
        },
        sections: {
          cardsSelector: {
            enabled: false,
            version: ZeVersion.Default,
          }
        }
      },
      telephonePaymentConfirmation: {
        constants: {
          hideAvatar: false,
          showGoToHomesiteButton: false
        },
        features: {
          redirectWithParams: {
            enabled: true,
            enabledByProduct: [ProductId.Commerce, ProductId.RC, ProductId.RcPro]
          }
        }
      },
      preparingPrice: {
        constants: {
          hideAvatar: false
        },
        features: {
          checkCompetenceManagement: {
            enabled: false
          }
        }
      }
    },
    paymentType: {
      telephone: {
        enabled:true
      },
      online: {
        enabled: true,
        paymentChannel: {
          T1: PaymentMedium.Bank,
          T2: PaymentMedium.Bank,
          PI: PaymentMedium.Bank
        }
      }
    }
  },
  features: {
    chat: {
      enabled: false
    }
  }
};

export const prodSegurosolBrandingConfig: BrandingConfig = {
  ...defaultSegurosolBrandingConfig,
  links: {
    mainSite: 'https://www.zurichempresas.es/',
    cookiesPolicy: 'https://zurichempresas.es/politica-de-cookies',
    privacyPolicy: 'https://zurichempresas.es/politica-de-privacidad',
    hiringPolicy: 'https://zurichempresas.es/politica-de-contratacion',
    informativeNote: ''
  },
};
