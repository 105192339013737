/* eslint-disable id-length */
import getBrandingFeaturesSettings from '@/helpers/feature-control/methods/getBrandingFeaturesSettings';
import {
  BrandingFeatureConfig
} from '../interfaces/configs/branding';

/**
 * Chat instance and utils
 */
class Chat {

  /**
   * Returns the configs of the chat if enabled
   * @returns {object | undefined}
   */
  private settings(): BrandingFeatureConfig['settings'] | undefined {
    const chatConfigs = getBrandingFeaturesSettings()?.chat;

    if (chatConfigs?.enabled) {
      return chatConfigs.settings;
    }

    return undefined;
  }

  /**
   * Handles the initialization of the chat
   * @param {any} g
   * @param {string} e
   * @param {string} n
   * @param {any} es
   * @param {any} ys
   */
  private handleInstall(
    g: any,
    e: string,
    n: string,
    es: any,
  ): void {
    g._genesysJs = e;
    g[e] = g[e] || function() {
      g[e].q = g[e].q || [];
      // eslint-disable-next-line prefer-rest-params
      g[e].q.push(arguments);
    };
    g[e].t = 1 * new Date().valueOf();
    g[e].c = es;
    const ys:any = document.createElement('script');
    ys.async = 1;
    ys.src = n;
    ys.charset = 'utf-8';
    document.head.appendChild(ys);
  }

  /**
   * Initializes the chat
   */
  public init(): void {
    const settings = this.settings();

    if (settings) {
      this.handleInstall(
        window,
        'Genesys',
        settings.url,
        {
          environment: settings.env,
          deploymentId: settings.deploymentId
        }
      );
    }
  }
}


export default Chat;
